import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import styles from './customModalStyle.js';
const useStyles = makeStyles(styles);

export default function CustomModal({ open, onClose, children, style, modalBodyClassName, title }) {
  const classes = useStyles();

  const modalBodyClasses = classNames({
    [classes.modalBody]: true,
    [modalBodyClassName]: !!modalBodyClassName,
  });

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
        container: classes.container,
      }}
      open={open}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.modalHeader}>
        {title && <h3 className={classes.modalTitle}>{title}</h3>}
        <Button simple className={classes.modalCloseButton} key="close" aria-label="Close" onClick={onClose}>
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent className={modalBodyClasses}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.gridItem} style={{ ...style, marginTop: 20 }}>
            {children}
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}

CustomModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  modalBodyClassName: PropTypes.string,
};
